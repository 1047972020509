import PostManager from "./post";
import PostForm from "./postform";

function App() {
  return (
    <div>
     <PostForm/>

    </div>
  );
}

export default App;
