import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Card, CardMedia, CardContent, Typography, Grid } from '@mui/material';

const PostForm = () => {
  const [posts, setPosts] = useState([]);
  const [title, setTitle] = useState('');
  const [babat, setBabat] = useState('');
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://mohamed-linux.com/api/api.php');
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image && !editId) {
      alert('Please select an image');
      return;
    }

    const formData = new FormData();
    if (image) {
      formData.append('image', image);
    }

    try {
      let imageUrl = '';
      if (image) {
        const imgbbApiKey = '2c153a82e4fb2433419c8f4d0112e533';
        const response = await axios.post(`https://api.imgbb.com/1/upload?key=${imgbbApiKey}`, formData);
        if (response.data && response.data.data && response.data.data.url) {
          imageUrl = response.data.data.url;
        } else {
          alert('Image upload failed.');
          return;
        }
      }

      const postData = {
        title,
        babat,
        imageUrl,
      };

      if (editId) {
        postData.id = editId;
        await axios.put('https://mohamed-linux.com/api/api.php', postData);
        alert('Post updated successfully!');
      } else {
        await axios.post('https://mohamed-linux.com/api/api.php', postData);
        alert('Post created successfully!');
      }

      setTitle('');
      setBabat('');
      setImage(null);
      setPreviewUrl('');
      setEditId(null);
      fetchPosts();
    } catch (error) {
      console.error('Error submitting post:', error);
      alert('An error occurred while submitting the post.');
    }
  };

  const handleEdit = (post) => {
    setTitle(post.title);
    setBabat(post.babat);
    setPreviewUrl(post.image);
    setEditId(post.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://mohamed-linux.com/api/api.php?id=${id}`);
      alert('Post deleted successfully!');
      fetchPosts();
    } catch (error) {
      console.error('Error deleting post:', error);
      alert('An error occurred while deleting the post.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Content"
          value={babat}
          onChange={(e) => setBabat(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <input type="file" accept="image/*" onChange={handleImageChange} />
        {previewUrl && (
          <CardMedia
            component="img"
            height="194"
            image={previewUrl}
            alt="Image preview"
            style={{ marginTop: '10px', marginBottom: '10px' }}
          />
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '10px' }}>
          {editId ? 'Update' : 'Submit'}
        </Button>
      </form>

      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post.id}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={post.image}
                alt={post.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {post.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {post.babat}
                </Typography>
                <Button onClick={() => handleEdit(post)} color="primary">
                  Edit
                </Button>
                <Button onClick={() => handleDelete(post.id)} color="secondary">
                  Delete
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PostForm;